import React, {FC, useEffect, useState} from "react";
import {ShareableUserData, Settings} from "../../../api/types";
import {Card, CardContent, Checkbox, Divider, FormControlLabel, Grid} from "@mui/material";
import {DialogSimpleFieldText} from "../../Reusable/DialogSimpleFieldText";
import {availablePlacements, getAvailableDisplayTypes} from "../WidgetSettingsCheckbox/WidgetSettingsCheckbox";
import {DialogSimpleFieldDropdown} from "../../Reusable/DialogSimpleFieldDropdown";
import ContentWrapper from "../../ContentWrapper/ContentWrapper";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setBrandId, setContentName, setTreeItems} from "../../../redux/slices/sequenceSlice";
import {setStep} from "../../../redux/slices/creatorSlice";
import {RootState} from "../../../redux/store";
import {getAssignableBrandsDataThunk} from "../../../redux/slices/brandSlice";
import StepOnePlaceholderImage from '../../../assets/brand-step-one.png'
import {GuidelinesPreview} from "../../Guidelines/GuidelinesPreview";
import {ExperienceLinkSelection} from "../../Experiences/ExperienceLinkSelection";
import {v4 as uuidv4} from "uuid";
import {useAppContext} from "../../../contexts/PermissionContext";


export const StepCreateCreator: FC<{
  link: ShareableUserData,
  setLink: React.Dispatch<React.SetStateAction<ShareableUserData>>,
  settings: Settings,
  setSettings: React.Dispatch<React.SetStateAction<Settings>>,
  linkRequired: Boolean,
}> = ({
  link,
  setLink,
  settings,
  setSettings,
  linkRequired,
}) => {

  const dispatch = useDispatch()
  const { permissions } = useAppContext()

  let availableDisplayTypes = getAvailableDisplayTypes(permissions)

  const contentName = useSelector(
    (state: RootState) => state.sequenceReducer.contentName,
  )

  const experienceId = useSelector(
      (state: RootState) => state.sequenceReducer.sequenceId,
  )

  const brandId = useSelector(
    (state: RootState) => state.brandsReducer.brands?.[0]?.id
  )

  const { items } = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceBox,
  )

  const [experienceName, setExperienceName] = useState(contentName)
  const [placement, setPlacement] = useState(settings?.widget?.displaySettings?.defaultPlacement)
  const [displayType, setDisplayType] = useState(settings?.widget?.displaySettings?.defaultDisplayType)
  const [isCreatorTemplate, setIsCreatorTemplate] = useState(settings?.template?.isCreatorTemplate)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (settings?.widget?.displaySettings) {
      setPlacement(settings.widget?.displaySettings?.defaultPlacement)
      setDisplayType(settings.widget?.displaySettings?.defaultDisplayType)
    }
    if (settings?.template?.isCreatorTemplate) {
      setIsCreatorTemplate(settings.template.isCreatorTemplate)
    }
  }, [settings])

  useEffect(() => {
    if (!brandId) {
      dispatch(getAssignableBrandsDataThunk())
    }
  }, [brandId])


  useEffect(() => {
    setIsValid(experienceName && experienceName.length > 0 && (!linkRequired || Boolean(link)))
    dispatch(setContentName(experienceName))
  }, [experienceName, link, linkRequired])

  const onCreateClick = () => {

    dispatch(setBrandId(brandId))

    let tempItems = JSON.parse(JSON.stringify(items))
    dispatch(setTreeItems(tempItems))

    setSettings({
      ...settings,
      widget: {
        ...settings.widget,
        displaySettings: {
          ...settings.widget?.displaySettings,
          defaultDisplayType: displayType,
          defaultPlacement: placement,
        }
      },
      template: {
        ...settings.template,
        isCreatorTemplate: isCreatorTemplate,
      }
    })

    dispatch(setStep(2))
  }

  return (
    <ContentWrapper>
      { settings && (
        <Card>
          <CardContent>

            <Grid container spacing={8} alignItems={"center"} p={4}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ExperienceLinkSelection link={link} setLink={setLink} linkRequired={linkRequired} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={12}>
                    <DialogSimpleFieldText label={"Name your campaign"} value={experienceName} setValue={setExperienceName} error={false}/>
                  </Grid>
                  <Grid item xs={12}>
                    <DialogSimpleFieldDropdown props={{ sx: { width: "100%" }}} label={"Display type"} value={displayType} setValue={setDisplayType} values={availableDisplayTypes}/>
                  </Grid>
                  <Grid item xs={12}>
                    <DialogSimpleFieldDropdown props={{ sx: { width: "100%" }}} label={"Default placement"} value={placement} setValue={setPlacement} values={availablePlacements}/>
                  </Grid>
                  {permissions?.experience?.templateSettings?.creator && (

                      <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox key={uuidv4()} color="primary" />}
                            label="Is creator template"
                            value={isCreatorTemplate}
                            checked={isCreatorTemplate}
                            onChange={(event, value) =>
                                setIsCreatorTemplate(value)
                            }
                        />
                      </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button sx={{ width: "100%" }} disabled={!isValid} variant={"contained"} onClick={onCreateClick}>
                      { experienceId ? 'Edit' : 'Create'}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <GuidelinesPreview showGuidelinesHeading={true}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src={StepOnePlaceholderImage} alt="step-one" style={{ width: "100%" }} />
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      )}
    </ContentWrapper>
  )
}