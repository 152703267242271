import {
  Grid,
  Typography,
  Card,
  Box,
  Divider,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import React, { useEffect, useState } from 'react'
import { getCreatorProfileDataThunk } from 'src/redux/slices/authSlice'
import { useDispatch } from 'react-redux'
import { CreatorProfileData } from '../../../api/types'
import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import ImgBG from '../../../assets/img-bg.svg'
import {creatorProfileUpdate, mediaStorageCreateItem, mediaStorageGetSingle} from 'src/api/services'
import { saveImage } from 'src/services/api/mediastorage'
import { useFormik } from 'formik'

type Props = {
  creatorProfileData: CreatorProfileData
}

const AutocompleteCustom = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
  '& .MuiChip-deleteIcon': {
    color: '#5569FF!important',
  },
}))

const Container = styled.div(() => ({
  display: 'flex',
  width: '80%',
  gap: '45px',
  paddingBottom: '40px',
  paddingLeft: '90px',
  paddingTop: '40px',
  '@media (max-width: 768px)': {
    paddingLeft: '0px',
    width: '100%',
    padding: '25px',
    paddingBottom: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
}))

const NameContainer = styled.div(() => ({
  display: 'flex',
}))

const NameBlock = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  justifyContent: 'flex-end',
  paddingLeft: '30px',
  marginBottom: '10px',
}))

const RightSideContainer = styled.div(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  paddingTop: '87px',
  gap: '40px',
  '@media (max-width: 768px)': {
    paddingTop: '0px',
    width: '100%',
  },
}))

const LeftSideContainer = styled.div(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '30px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
}))

const CreatorProfile: React.FC<Props> = ({ creatorProfileData }) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)

  const [locations, setLocations] = useState(
    creatorProfileData?.locations || [],
  )
  const [tags, setTags] = useState(creatorProfileData?.tags || [])
  const [interests, setInterests] = useState(
    creatorProfileData?.interests || [],
  )
  const [img, setImg] = useState({
    id: creatorProfileData?.pictureId,
    location: null,
  })

  useEffect(() => {
    if (creatorProfileData?.pictureId) {
      mediaStorageGetSingle(creatorProfileData?.pictureId).then(res =>
        setImg(res.data),
      )
    }
  }, [creatorProfileData?.pictureId])

  const displayNameMaxLength = 24

  const formik = useFormik({
    initialValues: {
      displayName: creatorProfileData?.displayName || '',
      socialMediaFollowers: creatorProfileData?.socialMediaFollowers || 0,
      note: creatorProfileData?.note || '',
    },
    validate: (values => {

      const errors = {}

      if (values.displayName.length > displayNameMaxLength) {
        errors.displayName = `Limit to ${displayNameMaxLength} characters`
      }

      return errors
    }),
    onSubmit: values => {
      const { displayName, socialMediaFollowers, note } = values
      const data = {
        ...creatorProfileData,
        displayName,
        socialMediaFollowers,
        tags,
        interests,
        locations,
        note,
        pictureId: img.id,
      }
      creatorProfileUpdate(data).then(() => {
        dispatch(getCreatorProfileDataThunk())
        setIsEditing(false)
      })
    },
  })

  const hiddenFileInput = React.useRef(null)

  const handleClick = _event => {
    hiddenFileInput.current.click()
  }

  const handleChangeFile = async event => {

    let createResponse = await mediaStorageCreateItem({ type: 'DIRECT' })
    let created = createResponse.data

    let fileData = new FormData()
    fileData.append('file', event?.target?.files[0])
    saveImage(fileData, created.id).then(res => {
      setImg(res)
    })
  }

  const ImageBlock = styled.div(() => ({
    width: '140px',
    minWidth: '140px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundImage: `url(${img.location || ImgBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    fontWeight: 'bold',
    borderRadius: '50%',
    textAlign: 'center',
    color: '#fff',
    cursor: 'pointer',
  }))

  const renderTags = elem => {
    return elem.map(x => (
      <Chip key={x} style={{ marginRight: '14px', marginBottom: '14px' }} label={x} />
    ))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Personal Details
              </Typography>
              <Typography variant="subtitle2">
                Manage information related to your personal details
              </Typography>
            </Box>
            {!isEditing ? (
              <Button
                variant="text"
                onClick={() => setIsEditing(true)}
                startIcon={<EditTwoToneIcon />}
              >
                Edit
              </Button>
            ) : (
              <Button disabled={Object.keys(formik.errors).length > 0} onClick={formik.submitForm}>Done</Button>
            )}
          </Box>
          <Divider />
          <Container>
            <LeftSideContainer>
              <NameContainer>
                {!isEditing ? (
                  <ImageBlock />
                ) : (
                  <ImageBlock onClick={handleClick}>
                    Upload <br />+
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChangeFile}
                      style={{ display: 'none' }}
                    />
                  </ImageBlock>
                )}
                <NameBlock>
                  {!isEditing ? (
                    <>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {creatorProfileData.firstName || '-'}{' '}
                        {creatorProfileData.lastName || '-'}
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {formik.values.displayName === 'null null'
                          ? '-'
                          : formik.values.displayName}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {creatorProfileData.firstName}{' '}
                        {creatorProfileData.lastName}
                      </Typography>
                      <TextField
                        style={{ marginTop: '23px' }}
                        onChange={formik.handleChange}
                        id="displayName"
                        type="text"
                        name="displayName"
                        value={formik.values.displayName}
                        label="Profile name*"
                        helperText={`Limit to ${displayNameMaxLength} characters`}
                        error={formik.errors.displayName}
                      />
                    </>
                  )}
                </NameBlock>
              </NameContainer>
              {!isEditing ? (
                <>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {formik.values.socialMediaFollowers} Social media followers
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Locations
                  </Typography>
                  <Typography style={{ marginTop: '-15px' }}>
                    {locations.length > 0 ? renderTags(locations) : '-'}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Industries
                  </Typography>
                  <Typography style={{ marginTop: '-15px' }}>
                    {interests.length > 0 ? renderTags(interests) : '-'}
                  </Typography>
                </>
              ) : (
                <>
                  <TextField
                    onChange={formik.handleChange}
                    id="socialMediaFollowers"
                    type="number"
                    name="socialMediaFollowers"
                    value={formik.values.socialMediaFollowers}
                    label="Social media followers"
                  />
                  <AutocompleteCustom
                    multiple
                    id="locations"
                    options={[]}
                    freeSolo
                    onChange={(_, value) => setLocations(value)}
                    defaultValue={creatorProfileData?.locations || []}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip key={option} label={option} {...getTagProps({ index })} />
                      ))
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Locations"
                        name="locations"
                        placeholder="Where are you based mostly? you can add multiple locations "
                        helperText={"Press enter after each location"}
                      />
                    )}
                  />
                  <AutocompleteCustom
                    multiple
                    options={[]}
                    freeSolo
                    onChange={(_, value) => setInterests(value)}
                    defaultValue={creatorProfileData?.interests || []}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip key={option} label={option} {...getTagProps({ index })} />
                      ))
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Industries"
                        placeholder="What are your industries and active interests "
                        helperText={"Press enter after each interest"}
                      />
                    )}
                  />
                </>
              )}
            </LeftSideContainer>
            <RightSideContainer>
              {!isEditing ? (
                <>
                  <Typography style={{ fontWeight: 'bold' }}>Tags</Typography>
                  <Typography style={{ marginTop: '-25px' }}>
                    {tags.length > 0 ? renderTags(tags) : '-'}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>Note</Typography>
                  <Typography style={{ marginTop: '-15px' }}>
                    {formik.values.note || '-'}
                  </Typography>
                </>
              ) : (
                <>
                  <AutocompleteCustom
                    multiple
                    options={[]}
                    freeSolo
                    onChange={(_, value) => setTags(value)}
                    defaultValue={creatorProfileData?.tags || []}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip key={option} label={option} {...getTagProps({ index })} />
                      ))
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        placeholder="Add hashtags that represent you"
                        helperText={"Press enter after each tag"}
                      />
                    )}
                  />
                  <TextField
                    onChange={formik.handleChange}
                    id="note"
                    type="text"
                    name="note"
                    value={formik.values.note}
                    multiline
                    sx={{
                      '& .MuiOutlinedInput-root': { height: '219px' },
                    }}
                    rows={9}
                    label="Note"
                  />
                </>
              )}
            </RightSideContainer>
          </Container>
        </Card>
      </Grid>
      <Divider />
    </Grid>
  )
}

export default CreatorProfile
